import { FC } from 'react'

type Props = {
    values?: any
}

const SubscriptionMasterSearchArchivedStatus: FC<Props> = ({ values }) => {
    return (values === 0) ? (
        <div className='badge badge-light-danger'>{'Pending'}</div>
    ) : (values === 1) ? (
        <div className='badge badge-light-success'>{'Active'}</div>
    ) : (values === 2) ? (
        <div className='badge badge-light-warning'>{'Inactive'}</div>
    ) : (values === 3) ? (
        <div className='badge badge-light-info'>{'Archived'}</div>
    ) : null
}

export { SubscriptionMasterSearchArchivedStatus }
