import { useCallback, useEffect, useMemo, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { createVehiclePackages, getPackagesById, updateVehiclePackages } from "../../packageVehicle/packageVehicle-list/core/_requests";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { getCategories } from "../../categories/category-list/core/_requests";
import { PackageVehicle } from "../../packageVehicle/packageVehicle-list/core/_models";
import clsx from "clsx";

const packageVehicleSchema = Yup.object().shape({
    vehicle_name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(100, 'Maximum 100 symbols')
        .required('Vehicle name is required'),
    plans: Yup.array().of(
        Yup.object().shape({
            daily_rate: Yup.number().min(0, 'Daily rate cannot be negative').required('Daily rate is required'),
            weekly_rate: Yup.number().min(0, 'Weekly rate cannot be negative').required('Weekly rate is required'),
            monthly_rate: Yup.number().min(0, 'Monthly rate cannot be negative').required('Monthly rate is required'),
        })
    ),
});

const initialPackageVehicle = {
    vehicle_name: '',
    plans: [],
    status: true,
};

export const VehiclePackageModal = ({ closeModal, isEdit, fetchData }: any) => {

    let planObj = { category_name: "", package_category: '', daily_rate: '', weekly_rate: '', monthly_rate: '', category_des: "" }

    const [isVehicle, setIsVehicle] = useState<PackageVehicle>(initialPackageVehicle);
    const [editedFields, setEditedFields] = useState<{ [key: string]: boolean }>({});

    const [isPlan, setIsPlan] = useState<PackageVehicle>()

    useEffect(() => {
        if (isEdit) {
            FetchDataById(isEdit)
        }
    }, [isEdit]);

    const FetchDataById = (id: any) => {
        getPackagesById(id).then((res: any) => {
            setIsPlan(res)
        })
    }

    useEffect(() => {
        getCategories("test").then((res: any) => {
            mappedPlan(res?.data?.docs);
        });
    }, [isPlan])

    const initialValues = {
        ...isVehicle,
        vehicle_name: isVehicle.vehicle_name || initialPackageVehicle.vehicle_name,
        plans: isVehicle.plans || initialPackageVehicle.plans,
        status: isVehicle.status ?? initialPackageVehicle.status,
    }

    const mappedPlan = useCallback((res: any) => {
        let plans = res.map((item: any) => {
            if (isPlan?._id) {
                const existingPlan = isPlan.plans.find((plan: any) => plan.package_category?._id === item?._id);
                return {
                    ...planObj,
                    package_category: item._id,
                    category_name: item.name,
                    category_des: item.descriptions,
                    daily_rate: existingPlan ? existingPlan.daily_rate : '',
                    weekly_rate: existingPlan ? existingPlan.weekly_rate : '',
                    monthly_rate: existingPlan ? existingPlan.monthly_rate : '',
                };
            } else {
                return { ...planObj, package_category: item._id, category_name: item.name, category_des: item.descriptions }
            }
        });
        setIsVehicle({ ...isPlan, plans } as PackageVehicle);
        formik.setFieldValue("plans", plans);
    }, [isVehicle, isPlan])

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: packageVehicleSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                if (values._id) {
                    await updateVehiclePackages(values);
                } else {
                    await createVehiclePackages(values);
                }
                closeModal()
                resetForm()
                fetchData()
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleFieldChange = (field: string, value: any) => {
        formik.setFieldValue(field, value);
        setEditedFields((prev: any) => ({ ...prev, [field]: true }));
    };

    useEffect(() => {
        formik.values.plans.forEach((plan: any, index: any) => {
            if (plan.category_name) {
                const weeklyRate = parseFloat(formik.values.plans[index].weekly_rate);
                const dailyField = `plans.${index}.daily_rate`;
                const monthlyField = `plans.${index}.monthly_rate`;

                if (!isNaN(weeklyRate) && weeklyRate > 0) {
                    // Calculate daily rate and monthly rate based on weekly rate if not manually edited
                    if (!editedFields[dailyField]) {
                        formik.setFieldValue(dailyField, (weeklyRate / 7).toFixed(2));
                    }
                    if (!editedFields[monthlyField]) {
                        formik.setFieldValue(monthlyField, (weeklyRate * 4).toFixed(2));
                    }
                } else {
                    if (!editedFields[dailyField]) {
                        formik.setFieldValue(dailyField, '');
                    }
                    if (!editedFields[monthlyField]) {
                        formik.setFieldValue(monthlyField, '');
                    }
                }
            }
        });
    }, [editedFields]);

    return (
        <>
            <div className='modal fade show d-block lg' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
                <div className='modal-dialog modal-dialog-centered mw-1000px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>{isEdit ? 'Edit Vehicle Plan' : 'Add Vehicle Plan'}</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                        </div>
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            <form className='form' onSubmit={formik.handleSubmit} noValidate>
                                <div className='d-flex flex-column scroll-y me-n7 pe-7' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
                                    <div className='fv-row mb-7 p-2'>
                                        <label className='required fw-bold fs-6 mb-2'>Vehicle Name</label>
                                        <input
                                            placeholder='Vehicle Name'
                                            {...formik.getFieldProps('vehicle_name')}
                                            className={clsx('form-control form-control-solid mb-3 mb-lg-0', { 'is-invalid': formik.touched.vehicle_name && formik.errors.vehicle_name }, { 'is-valid': formik.touched.vehicle_name && !formik.errors.vehicle_name })}
                                            type='text'
                                            name='vehicle_name'
                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                        />
                                        {formik.touched.vehicle_name && formik.errors.vehicle_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.vehicle_name as any}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='fv-row mb-7 p-2'>
                                        <label className='fw-bold fs-6 mb-2'>Plans</label>
                                        <table className='table table-bordered'>
                                            <thead className="table-gray">
                                                <tr>
                                                    <th>Packages</th>
                                                    <th>Daily Rate</th>
                                                    <th>Weekly Rate (7 days)</th>
                                                    <th>Monthly Rate (28 days)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formik.values.plans?.map((plan: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span>{plan?.category_name || 'Category'}</span>
                                                            <p className="text-muted small">{plan?.category_des}</p>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min="0"
                                                                style={{ border: 'none' }}
                                                                {...formik.getFieldProps(`plans.${index}.daily_rate`)}
                                                                placeholder='Daily Rate'
                                                                disabled={formik.isSubmitting}
                                                                onChange={(e) => handleFieldChange(`plans.${index}.daily_rate`, e.target.value)}
                                                            />
                                                            {(formik.touched.plans as any)?.[index]?.daily_rate && (formik.errors.plans as any)?.[index]?.daily_rate && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                        <span role='alert'>{(formik.errors.plans as any)[index].daily_rate}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min="0"
                                                                style={{ border: 'none' }}
                                                                {...formik.getFieldProps(`plans.${index}.weekly_rate`)}
                                                                placeholder='Weekly Rate'
                                                                disabled={formik.isSubmitting}
                                                                onChange={(e) => handleFieldChange(`plans.${index}.weekly_rate`, e.target.value)}
                                                            />
                                                            {(formik.touched.plans as any)?.[index]?.weekly_rate && (formik.errors.plans as any)?.[index]?.weekly_rate && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                        <span role='alert'>{(formik.errors.plans as any)[index].weekly_rate}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min="0"
                                                                style={{ border: 'none' }}
                                                                {...formik.getFieldProps(`plans.${index}.monthly_rate`)}
                                                                placeholder='Monthly Rate'
                                                                disabled={formik.isSubmitting}
                                                                onChange={(e) => handleFieldChange(`plans.${index}.monthly_rate`, e.target.value)}
                                                            />
                                                            {(formik.touched.plans as any)?.[index]?.monthly_rate && (formik.errors.plans as any)?.[index]?.monthly_rate && (
                                                                <div className='fv-plugins-message-container'>
                                                                    <div className='fv-help-block'>
                                                                        <span role='alert'>{(formik.errors.plans as any)[index].monthly_rate}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='fv-row mb-7 p-2'>
                                        <label className='fw-bold fs-6 mb-2'>Status</label>
                                        <select
                                            {...formik.getFieldProps('status')}
                                            className={clsx('form-select form-select-solid', { 'is-invalid': formik.touched.status && formik.errors.status })}
                                            disabled={formik.isSubmitting}
                                        >
                                            <option value={String(true)} selected={formik.values.status === true}>
                                                Active
                                            </option>
                                            <option value={String(false)} selected={formik.values.status === false}>
                                                Inactive
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => closeModal()}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                        disabled={formik.isSubmitting}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>{isVehicle?._id ? 'Update' : 'Submit'}</span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    );
};
