import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { SubscriptionArchivedListWrapper } from './subscriptions-archived-list/SubscriptionsArchivedList'

const customersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Rentals',
    path: '/Subscriptions/archived-rentals',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SubscriptionArchivedPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='archived-rentals'
          element={
            <>
              <PageTitle breadcrumbs={customersBreadcrumbs}>Archived Rentals</PageTitle>
              <SubscriptionArchivedListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/subscriptions/archived-rentals' />} />
    </Routes>
  )
}

export default SubscriptionArchivedPage
