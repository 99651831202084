import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SubscriptionsMasterSearchHeader} from './components/header/SubscriptionsArchivedHeader'
import {SubscriptionsArchivedTable} from './table/SubscriptionsArchivedTable'
import {SubscriptionsEditModal} from './subscriptions-edit-modal/SubscriptionsEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const SubscriptionsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <SubscriptionsMasterSearchHeader />
        <SubscriptionsArchivedTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SubscriptionsEditModal />}
    </>
  )
}

const SubscriptionArchivedListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SubscriptionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SubscriptionArchivedListWrapper}
