import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { getActiveRentalWithPagination } from '../subscriptions/core/_requests'
import { useNavigate } from 'react-router-dom'
import { ActiveAccountsPagination } from './components/ActiveAccountsPagination'

export interface Subscription {
    organization: {
        organization_name: string
        organization_id: string
    }
}

export type Subscriptions = Subscription[];

const ActiveAccounts: React.FC = () => {

    const navigate = useNavigate();
    const [org, setOrg] = useState<Subscriptions>();
    const [activeAccounts, setActiveAccounts] = useState<{ organization_name: string; count: number }[]>([])

    const [searchKey, setSearchKey] = useState('');
    const [pagination, setPagination] = useState<{ page: number; totalPages: number }>({ page: 1, totalPages: 0 });

    useEffect(() => {
        getActiveRentalWithPagination(pagination.page, `search=${searchKey}`).then((res: any) => {
            const data = res?.data.docs || []
            setOrg(data)
            setActiveAccounts(data)
            setPagination({ page: res.data.page, totalPages: res.data.totalPages });
        })
    }, [searchKey, pagination.page])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(event.target.value);
    };

    const handlePageChange = (page: number) => {
        setPagination((prev) => ({ ...prev, page }));
    };

    const navigateCompanyProfile = (orgName: string) => {
        const foundOrganization = org && org.find((item: any) => item.organization.organization_name === orgName);
        if (foundOrganization) {
            const organization_id = foundOrganization.organization?.organization_id;
            navigate('/customer-management/overview', { state: { organization_id } });
        } else {
            console.log('Organization not found');
        }
    };
    
    return (
        <>
            <div className='card'>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                value={searchKey}
                                onChange={handleSearch}
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search'
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                </div>
                <div className="card-body py-4">
                    <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="table-responsive">
                            {activeAccounts?.length > 0 ? (
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                    <thead>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <th className="w-10px pe-2">
                                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input className="form-check-input" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" />
                                                </div>
                                            </th>
                                            <th className='min-w-125px'>Organization Name</th>
                                            <th className='min-w-125px'>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 fw-bold text-center">
                                        {activeAccounts.map((org, index) => (
                                            <tr key={index} role='row'>
                                                <td role="cell">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" />
                                                    </div>
                                                </td>
                                                <td role="cell">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            <span className="text-gray-800 text-hover-primary cursor-pointer mb-1" onClick={() => navigateCompanyProfile(org.organization_name)}>{org.organization_name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td role="cell">
                                                    <div className="d-flex">
                                                        <span className="text-gray-800">{org.count}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <> {<div className='mt-5'>No records found!</div>}</>
                            )}
                        </div>
                    </div>
                </div>
                {activeAccounts?.length > 0 && (
                    <ActiveAccountsPagination pagination={pagination} onPageChange={handlePageChange} />
                )}
            </div>
        </>
    )
}

export default ActiveAccounts
