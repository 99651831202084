import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {User, TransactionQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`

const GET_TRANSACTION_URL = `${BACKEND_API_URL}/transactions/get-bp-paginated-transactions`
const GET_ALL_TRANSACTION_URL = `${BACKEND_API_URL}/transactions/get-all-bp-transactions`
const GET_RENTAL_ID_TRANSACTION_URL = `${BACKEND_API_URL}/transactions/get-bp-rental-id-transactions`

const getTransactionLists = (query: string): Promise<TransactionQueryResponse> => {
  return axios
    .get(`${GET_TRANSACTION_URL}?${query}`)
    .then((d: AxiosResponse<TransactionQueryResponse>) => d.data)
}

const getAllTransactionLists = (query: string): Promise<TransactionQueryResponse> => {
  return axios
    .get(`${GET_ALL_TRANSACTION_URL}?${query}`)
    .then((d: AxiosResponse<TransactionQueryResponse>) => d.data)
}

// const getSingleContactTransaction = async (crn3: string): Promise<TransactionQueryResponse> => {
//   return await axios
//     .get(`${GET_TRANSACTION_URL}?crn3=${crn3}`)
//     .then((d: AxiosResponse<TransactionQueryResponse>) => d.data)
// }

const getSingleContactTransaction = (
  page: number,
  id: string
): Promise<TransactionQueryResponse | undefined> => {
  const query = `page=${page}`
  return axios
    .get(`${GET_TRANSACTION_URL}?${query}&search=${id}`)
    .then((response: AxiosResponse<Response<TransactionQueryResponse>>) => response.data)
    .then((response: Response<TransactionQueryResponse>) => response.data)
}

const getSingleRentalIdTransaction = (
  page: number,
  id: string,
  rentalid: string
): Promise<TransactionQueryResponse | undefined> => {
  const query = `page=${page}`
  return axios
    .get(`${GET_RENTAL_ID_TRANSACTION_URL}?${query}&search=${id}&rentalid=${rentalid}`)
    .then((response: AxiosResponse<Response<TransactionQueryResponse>>) => response.data)
    .then((response: Response<TransactionQueryResponse>) => response.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getTransactionLists,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getSingleContactTransaction,
  getAllTransactionLists,
  getSingleRentalIdTransaction,
}
