import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import {
  User,
  UsersQueryResponse,
  SubscriptionsQueryResponse,
  Subscriptions,
  CWFormData,
} from './_models'
import { CreditWatch } from '../subscriptions-cw-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`
// const GET_USERS_URL = `${API_URL}/users/query`
const GET_ACTIVE_SUBS_URL = `${BACKEND_API_URL}/subscriptions/get-subscriptions`
const UPDATE_SUBSCRIPTION = `${BACKEND_API_URL}/subscriptions/update-subscriptions`

const GET_ACTIVE_ACCOUNTS = `${BACKEND_API_URL}/subscriptions/get-active-accounts`

// const GET_ACTIVE_SUBS_URL = `${BACKEND_API_URL}/transactions/get-bp-transactions`
const GET_SUBSCRIBER_URL = `${BACKEND_API_URL}/subscriptions/get-subscription-details`
const UPDATE_CW_STATUS_URL = `${BACKEND_API_URL}/subscriptions/approve-cw-status`
const UPDATE_CW_STATUS_DOC_REQ_URL = `${BACKEND_API_URL}/subscriptions/ts-approved-doc-req`
const Create_Watchlists_checkout = `${BACKEND_API_URL}/subscriptions/submit-credit-watch-checklist`
const Get_Watchlists_Checkout = `${BACKEND_API_URL}/subscriptions/get-credit-watch-checklist`
const Edit_Watchlists_Checkout = `${BACKEND_API_URL}/subscriptions/update-credit-watch-checklist`
const GET_CREDIT_WATCH_APP_DETAILS = `${BACKEND_API_URL}/subscriptions/get-application-details`
const GET_CREDIT_WATCH_FORM_DETAILS = `${BACKEND_API_URL}/subscriptions/get-application`
const UPDATE_CW_STATUS_DECLINED_URL = `${BACKEND_API_URL}/subscriptions/decline-cw-status`
const UPDATE_CW_STATUS_INCOMPLETE_URL = `${BACKEND_API_URL}/subscriptions/incomplete-cw-status`
const UPDATE_CW_STATUS_ACCOUNT_REVIEW_URL = `${BACKEND_API_URL}/subscriptions/account-review-cw-status`
const DOWNLOAD_REPORT = `${BACKEND_API_URL}/subscriptions/download-cw-report`
const UPLOAD_CREDIT_WATCHLIST = `${BACKEND_API_URL}/subscriptions/upload-file-cw`
const SET_DEFAULT_PAYMENT_METHOD = `${BACKEND_API_URL}/subscriptions/set-default-payment-method`
const GET_CW_FILE_UID = `${BACKEND_API_URL}/subscriptions/get-files-uid`
const Delete_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/remove-packages`
const Approve_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/packages/approve`
const GET_COMMENTS_LIST = `${BACKEND_API_URL}/subscriptions/get-all-comment`
const ADD_NEW_COMMENTS = `${BACKEND_API_URL}/subscriptions/create-comment`
// const Check_Approve_Subs_Pkg = `${BACKEND_API_URL}/subscriptions/packages/approved`
const GET_CREDIT_SCORE = `${BACKEND_API_URL}/subscriptions/get-credit-score`
const CREATE_INVOICE = `${BACKEND_API_URL}/invoices/create-invoice`
const SUBMIT_CREATE_DRAFT_APPLICATION = `${BACKEND_API_URL}/subscriptions/create-draft-application-manually`
const GET_ASSESSMENT_COMMENTS = `${BACKEND_API_URL}/subscriptions/get-app-comment`
const ADD_ASSESSMENT_COMMENTS = `${BACKEND_API_URL}/subscriptions/add-app-comment`
const CREATE_XERO_CONTACT = `${BACKEND_API_URL}/invoices/create-xero-contact`
const ADD_NEW_RENTAL = `${BACKEND_API_URL}/subscriptions/submit-subscriptions`
const QUICK_APPROVE_BULK_RENTAL = `${BACKEND_API_URL}/subscriptions/quick-approve-to-account-bulk-subscription`
const CREATE_INVOICE_ON_SUBS = `${BACKEND_API_URL}/invoices/create-invoice-on-subscription`

const ADD_NEW_CARDS = `${BACKEND_API_URL}/transactions/add-new-card`
const GET_ACCOUNTS_USERS = `${BACKEND_API_URL}/user/get-accounts-user`
const CHANGE_COORDINATOR_FOR_RENTALS = `${BACKEND_API_URL}/subscriptions/change-coordinator-for-rentals`

const GET_BRANDING_THEME = `${BACKEND_API_URL}/invoices/get-xero-branding-theme`
const GET_ITEM_CODES = `${BACKEND_API_URL}/invoices/get-xero-item-code`
const GET_JOB_BY_ID = `${BACKEND_API_URL}/zoho-api/get-job-by-id`

const GET_ALL_PACKAGES = `${BACKEND_API_URL}/zoho/get-packages`

const GET_DEAL_DETAILS = `${BACKEND_API_URL}/subscriptions/get-deal-details`
const RELOAD_DOCUMENTS = `${BACKEND_API_URL}/subscriptions/reload-downloaded-files`
const MOVE_TO_ARCHIVED = `${BACKEND_API_URL}/subscriptions/send-to-archive`
const MOVE_TO_ARCHIVED_RENTAL = `${BACKEND_API_URL}/subscriptions/archive-subscription`

const getSubscriberById = async (subscription_id: string): Promise<Subscriptions | undefined> => {
  return axios
    .get(`${GET_SUBSCRIBER_URL}/?sid=${subscription_id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

// Update Credit watch status

// const setCreditWatchStatus = async (params: object): Promise<Subscriptions | undefined> => {
//   const response = await axios.get(`${UPDATE_CW_STATUS_URL}?sid=${subscription_id}`)
//   const response_1 = response.data
//   return response_1
// }
const createInvoice = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${CREATE_INVOICE}`, params)
  const response_1 = response
  return response_1.data
}

const setCWStatusDocReq = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_DOC_REQ_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatus = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_URL}`, params)
  const response_1 = response
  return response_1.data
}

const changeCoOrdinatorForRental = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${CHANGE_COORDINATOR_FOR_RENTALS}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusDeclined = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_DECLINED_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusIncomplete = async (params: object): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_INCOMPLETE_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setCreditWatchStatusAccountReview = async (
  params: object
): Promise<CreditWatch | undefined> => {
  const response = await axios.post(`${UPDATE_CW_STATUS_ACCOUNT_REVIEW_URL}`, params)
  const response_1 = response
  return response_1.data
}

const setDefaultPaymentMethod = async (params: object): Promise<Subscriptions | undefined> => {
  const response = await axios.post(`${SET_DEFAULT_PAYMENT_METHOD}`, params)
  const response_1 = response
  return response_1.data
}

const updateSubscription = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${GET_SUBSCRIBER_URL}/${subscription.id}`, subscription)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getActiveRental = (query: string): Promise<SubscriptionsQueryResponse> => {
  return axios
    .get(`${GET_ACTIVE_SUBS_URL}?${query}`)
    .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

const getActiveRentalWithPagination = (
  page: number,
  search: string
): Promise<SubscriptionsQueryResponse> => {
  const query = `page=${page}`
  return axios
    .get(`${GET_ACTIVE_ACCOUNTS}?${query}&${search}`)
    .then((d: AxiosResponse<SubscriptionsQueryResponse>) => d.data)
}

const getAccountUsers = async (): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_ACCOUNTS_USERS}`)
  return d.data
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${GET_SUBSCRIBER_URL}/${subscription.id}`, subscription)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const addWatchlistCheckout = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${Create_Watchlists_checkout}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getWatchlistCheckout = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .get(`${Get_Watchlists_Checkout}?application_id=${subscription}`)
    .then((response: AxiosResponse<Response<Subscriptions>>) => response.data)
    .then((response: Response<Subscriptions>) => response.data)
}

const submitCreateDraftApplication = async (
  subscription: Subscriptions
): Promise<Subscriptions | undefined> => {
  try {
    const response = await axios.post(`${SUBMIT_CREATE_DRAFT_APPLICATION}`, subscription)
    return response.data
  } catch (error) {
    console.error('API error:', error)
    throw error
  }
}
const createXeroContact = async (
  contactdata: Subscriptions
): Promise<Subscriptions | undefined> => {
  try {
    console.log(contactdata)
    const response = await axios.post(`${CREATE_XERO_CONTACT}`, contactdata)
    return response.data
  } catch (error) {
    console.error('API error:', error)
    throw error
  }
}

const downloadWatchlistReport = async (
  appid: string,
  uid: string
): Promise<Subscriptions | undefined> => {
  const response = await axios.get(`${DOWNLOAD_REPORT}?appid=${appid}&uid=${uid}`)
  const response_1 = response.data
  return response_1.data
}

const getCreditWatchApplicationDetails = async (
  app_id: string
): Promise<CreditWatch | undefined> => {
  const response = await axios.get(`${GET_CREDIT_WATCH_APP_DETAILS}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const getDealDetails = async (dealId: string): Promise<CreditWatch | undefined> => {
  const response = await axios.get(`${GET_DEAL_DETAILS}?dealId=${dealId}`)
  const response_1 = response.data
  return response_1.data
}

const getCWApplicationFormDetails = async (app_id: string): Promise<CWFormData | undefined> => {
  const response = await axios.post(`${GET_CREDIT_WATCH_FORM_DETAILS}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const getCreditScore = async (payload: {
  abn?: string
  acn?: string
}): Promise<CreditWatch | undefined> => {
  // query parameters dynamically
  const params = new URLSearchParams()
  if (payload.abn) params.append('abn', payload.abn)
  if (payload.acn) params.append('acn', payload.acn)

  try {
    const response = await axios.post(`${GET_CREDIT_SCORE}?${params.toString()}`)
    return response.data?.data
  } catch (error) {
    console.error('Error fetching credit score:', error)
    throw error
  }
}

const editWatchlistCheckout = (
  app_id: string,
  subscription: Subscriptions
): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${Edit_Watchlists_Checkout}?application_id=${app_id}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const watchlistFileUpload = (data: any, appId: Subscriptions): Promise<any | undefined> => {
  return axios
    .post(
      `${UPLOAD_CREDIT_WATCHLIST}?app_id=${appId}`,
      data, // The FormData object containing your files
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensures the request uses multipart headers
        },
      }
    )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
    .catch((error) => {
      console.error('File upload failed:', error)
      throw error
    })
}

const reloadDocuments = (app_id: string): Promise<any> => {
  return axios
    .post(`${RELOAD_DOCUMENTS}?appid=${app_id}`)
    .then((response) => response.data)
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "Server error.";
      throw new Error(errorMessage);
    });
};

const getCWFileUid = async (app_id: string): Promise<CreditWatch | undefined> => {
  const response = await axios.get(`${GET_CW_FILE_UID}?appid=${app_id}`)
  const response_1 = response.data
  return response_1.data
}

const deletePkg = (_id: Subscriptions, pkgId: any): Promise<void> => {
  return axios.delete(`${Delete_Subs_Pkg}?subscriptionId=${_id}&packageId=${pkgId}`).then(() => {})
}

const approvePkg = (_id: Subscriptions, pkgId: any): Promise<void> => {
  return axios.put(`${Approve_Subs_Pkg}?subscriptionId=${_id}&packageId=${pkgId}`).then(() => {})
}

const getRentalNotes = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_COMMENTS_LIST}?${query}`)
  return d.data
}

const createNotes = (payload: any): Promise<any | undefined> => {
  return axios
    .post(`${ADD_NEW_COMMENTS}`, payload)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getAppAssessmentComment = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_ASSESSMENT_COMMENTS}?${query}`)
  return d.data
}

const createAppAssessmentComment = (payload: any): Promise<any | undefined> => {
  return axios
    .put(`${ADD_ASSESSMENT_COMMENTS}`, payload)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const _addNewRental = (subscription: Subscriptions): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${ADD_NEW_RENTAL}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const _quickApproveToAccountBulkRental = (
  subscription: Subscriptions
): Promise<Subscriptions | undefined> => {
  return axios
    .post(`${QUICK_APPROVE_BULK_RENTAL}`, subscription)
    .then((response: AxiosResponse<Subscriptions>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const addNewCards = (card: any): Promise<any | undefined> => {
  return axios
    .post(`${ADD_NEW_CARDS}`, card)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getBrandingTheme = async (): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_BRANDING_THEME}`)
  return d.data
}

const getItemCodes = async (): Promise<any> => {
  const d = await axios.get(`${GET_ITEM_CODES}`)
  return d.data
}

const getJobSignedDetails = async (jobId: string): Promise<Subscriptions | undefined> => {
  const res = await axios.get(`${GET_JOB_BY_ID}?jobId=${jobId}`)
  return res.data.data
}

const createInvoiceOnSubscription = async (params: object): Promise<any | undefined> => {
  const response = await axios.post(`${CREATE_INVOICE_ON_SUBS}`, params)
  return response
}

// const getAllPackages = async (): Promise<any> => {
//   const d = await axios.get(`${GET_ALL_PACKAGES}`)
//   return d.data
// }

const getAllPackages = async (): Promise<any> => {
  try {
    const response = await axios.get(`${GET_ALL_PACKAGES}`)
    return response.data
  } catch (error) {
    console.error('Error fetching all packages:', error)
    throw error
  }
}

// const _updateSubscription = (card: any): Promise<any | undefined> => {
//   return axios
//     .put(`${UPDATE_SUBSCRIPTION}`, card)
//     .then((response: AxiosResponse<any>) => response.data)
//     .catch((error) => {
//       console.error('API error:', error)
//       throw error
//     })
// }

const _updateSubscription = async (params: any): Promise<any> => {
  const {subscription_id, ...data} = params
  return axios
    .put(`${UPDATE_SUBSCRIPTION}?subscription_id=${subscription_id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error // Rethrow for handling in calling code
    })
}

const moveToArchivedCW = (subsId: any): Promise<any | undefined> => {
  return axios
    .post(`${MOVE_TO_ARCHIVED}?subsId=${subsId}`)
    .then((response: AxiosResponse<any>) => response)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const moveToArchivedRental = (subsId: any): Promise<any | undefined> => {
  return axios
    .post(`${MOVE_TO_ARCHIVED_RENTAL}?subsId=${subsId}`)
    .then((response: AxiosResponse<any>) => response)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

export {
  _addNewRental,
  _quickApproveToAccountBulkRental,
  getSubscriberById,
  updateSubscription,
  getActiveRental,
  getActiveRentalWithPagination,
  getUserById,
  updateUser,
  setCreditWatchStatus,
  addWatchlistCheckout,
  getWatchlistCheckout,
  editWatchlistCheckout,
  getCreditWatchApplicationDetails,
  getCWApplicationFormDetails,
  setCreditWatchStatusDeclined,
  setCreditWatchStatusIncomplete,
  downloadWatchlistReport,
  watchlistFileUpload,
  setDefaultPaymentMethod,
  getCWFileUid,
  deletePkg,
  approvePkg,
  setCreditWatchStatusAccountReview,
  getCreditScore,
  getRentalNotes,
  createNotes,
  setCWStatusDocReq,
  createInvoice,
  getAppAssessmentComment,
  createAppAssessmentComment,
  addNewCards,
  submitCreateDraftApplication,
  getBrandingTheme,
  getItemCodes,
  getJobSignedDetails,
  createXeroContact,
  getAccountUsers,
  changeCoOrdinatorForRental,
  createInvoiceOnSubscription,
  getAllPackages,
  _updateSubscription,
  getDealDetails,
  reloadDocuments,
  moveToArchivedCW,
  moveToArchivedRental
}
