import {useListView} from '../../core/ListViewProvider'
import { SubscriptionsMasterSearchComponent } from './SubscriptionsMasterSearchComponent'
import { SubscriptionsMasterSearchToolbar } from './SubscriptionsMasterSearchToolbar'

const SubscriptionsMasterSearchHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SubscriptionsMasterSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? (
          <SubsPendingApplicationGrouping />
        ) : ( */}
          <SubscriptionsMasterSearchToolbar />
        {/* )} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SubscriptionsMasterSearchHeader}
