import moment from 'moment'
const BASE_FILE_URL = process.env.REACT_APP_FILE_URL
const AWS_S3_FILE_URL = process.env.REACT_APP_S3_FILE_URL

export function DateFormat(date: string) {
  return moment(date).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export function DateTimeFormat(date: string) {
  return date.split(/[?#]/)[0]
}

export function DateDifference(date1: any, date2: any) {
  const billdate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const startdate = new Date(moment(date2).format('YYYY-MM-DD'))
  const diff = billdate.getTime() - startdate.getTime()
  const msInDay = 1000 * 60 * 60 * 24
  const days = Math.floor(diff / msInDay)
  let result = ''

  if (diff > 0) {
    result = `Overdue from ${Math.abs(days)} days`
  } else {
    result = `Due in ${Math.abs(days)} days`
  }

  return result
}

export function NumberToWord(n: any) {
  if (n < 0) return false
  let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  let double_digit = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  if (n === 0) return 'Zero'
  function translate(n: any) {
    let word = ''
    if (n < 10) {
      word = single_digit[n] + ' '
    } else if (n < 20) {
      word = double_digit[n - 10] + ' '
    } else if (n < 100) {
      let rem = translate(n % 10)
      word = below_hundred[(n - (n % 10)) / 10 - 2] + ' ' + rem
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
    } else if (n < 1000000) {
      word = translate(n / 1000).trim() + ' Thousand ' + translate(n % 1000)
    } else if (n < 1000000000) {
      word = translate(n / 1000000).trim() + ' Million ' + translate(n % 1000000)
    } else {
      word = translate(n / 1000000000).trim() + ' Billion ' + translate(n % 1000000000)
    }
    return word
  }
  let result = translate(n)
  return result.trim()
}

export function AmountFormatter(n?: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(Number(n))
}

export function _addDaysInDate(datetoadd: string = '', daytoadd: any = 0) {
  const date = new Date(datetoadd)

  const numberOfDaysToAdd = parseInt(daytoadd)
  // console.log('add date', datetoadd, date, daytoadd, numberOfDaysToAdd)
  return date.setDate(date.getDate() + numberOfDaysToAdd)
}

export function _dayCounter(datetoadd: string = '', daytoadd: any = 0) {
  const currentDate: any = new Date()
  const toDate: any = new Date(_addDaysInDate(datetoadd, daytoadd))

  const differenceInMilliseconds = toDate - currentDate

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return differenceInDays
}

export const chargesAndFees = [
  "Excess KM's charge",
  'Bond 1 Low',
  'Bond 2 Middle',
  'Bond 3 High',
  'Bond 4 High Risk',
  'Accident Excess',
  'Accident Age Excess 1',
  'Accident Age Excess 2',
  'Cleaning Fee (Mild)',
  'Cleaning Fee (Severe)',
  'Vehicle recovery fee (Metro)',
  'Vehicle Recovery Fee (Rural)',
  'Early Contract Termination Fee',
  'Rubbish Removal $100.00',
  'Signage Option 1 Single Cab (no tray)',
  'Signage Option 2 Dual Cab (no tray)',
  'Signage Option 3 Single Cab (with Tray)',
  'Signage Option 4 Dual Cab (with Tray)',
  'Signage Option 5 Dual Cab (tub)',
  'Admin Charge',
  'PPSR Charge',
  'PMSI Charge',
  'Tolls admin fee',
  'Infringement fee',
  'Late payment fee',
  'Lost Key',
  'Lost Key Toolbox',
  'Lost Jack / Toolkit',
  'Lost/ Damaged Fuel Filler Cap',
  'Lost Ladder Rack',
  'Damaged side Mirror (repair)',
  'Damaged side Mirror (replacement)',
  'Toolbox replacement',
  'Badge replacement',
  'Number plate replacement',
  'Spare wheel',
  'Rubber mats',
  'Headlight Replacement',
  'Front Bumper Replacement',
  'Tail light protector replacement',
  'Tail light replacement',
  'Wipers',
  'Handbrake Adjustment',
  'Tyre Rotation',
  'Fuel Filter',
  'Brake Pads',
  'Disc rotors',
  'Tyre Puncture',
  'Light Globe',
  'Stone Chip',
  'Wheel Alignment',
]

export function calculateEndDate(
  deliveryDateTime: string | undefined,
  contractDays: number | undefined // Duration in days
): string {
  if (!deliveryDateTime) {
    return 'No date'
  }

  if (!contractDays || contractDays <= 0) {
    return '' // If contract_days is invalid
  }

  const deliveryDate = new Date(deliveryDateTime)

  // Add the contract days to the delivery date
  deliveryDate.setDate(deliveryDate.getDate() + contractDays)

  // Subtract 1 day to set the subscription's last active day
  // deliveryDate.setDate(deliveryDate.getDate() - 1);

  // Return date in YYYY-MM-DD format
  return deliveryDate.toISOString().split('T')[0]
}

// export function calculateMinEndDate(
//   deliveryDateTime: string | undefined,
//   packageType: string | undefined,
//   contractDays: number | undefined
// ) {
//   try {
//     const deliveryDate = moment(deliveryDateTime, moment.ISO_8601, true)
//     if (!deliveryDate.isValid()) {
//       throw new Error('No deliveryDateTime')
//     }

//     let minEndDate
//     let daysRemaining = 0

//     switch (packageType) {
//       case 'Short Term Direct':
//         minEndDate = deliveryDate.add(7, 'days') // Add 7 days for Short Term Direct
//         daysRemaining = 7
//         break

//       case 'FlexiRent':
//         minEndDate = deliveryDate.add(3, 'months') // Add 3 months for FlexiRent
//         daysRemaining = 90
//         break

//       case 'Rent To Own':
//         if (contractDays === 1456) {
//           minEndDate = deliveryDate.add(12, 'months') // Add 12 months for 4-year Rent To Own
//           daysRemaining = 364
//         } else if (contractDays === 1820) {
//           minEndDate = deliveryDate.add(24, 'months') // Add 24 months for 5-year Rent To Own
//           daysRemaining = 728
//         } else {
//           throw new Error('No duration for Rent To Own')
//         }
//         break

//       default:
//         // For other packages, return empty string
//         return {
//           minEndDate: '',
//           daysRemaining: 0,
//         }
//     }

//     // Subtract one day for subscription end date
//     minEndDate = minEndDate.subtract(1, 'day')

//     return {
//       minEndDate: minEndDate.format('YYYY-MM-DD'),
//       daysRemaining: daysRemaining, // Directly use predefined values
//     }
//   } catch (error) {
//     return {
//       minEndDate: '-',
//       daysRemaining: 0,
//     }
//   }
// }

export function calculateMinEndDate(
  deliveryDateTime: string | undefined,
  minimumContractDays: number | undefined
) {
  try {
    const deliveryDate = moment(deliveryDateTime, moment.ISO_8601, true)
    if (!deliveryDate.isValid() || !minimumContractDays) {
      throw new Error('Invalid delivery date or contract days')
    }

    // Calculate the minimum end date by adding minimumContractDays
    const minEndDate = deliveryDate.add(minimumContractDays, 'days').subtract(1, 'day')

    return {
      minEndDate: minEndDate.format('YYYY-MM-DD'),
      daysRemaining: minimumContractDays, // Set remaining days equal to contract duration
    }
  } catch (error) {
    return {
      minEndDate: '-',
      daysRemaining: 0,
    }
  }
}

export const getCardTypeName = (cardType: string | undefined) => {
  switch (cardType) {
    case 'MC':
      return 'Master Card'
    case 'VC':
      return 'Visa'
    case 'JC':
      return 'JCB'
    case 'AX':
      return 'American Express'
    case 'DC':
      return 'Diners'
    default:
      return 'No type'
  }
}

export const formatDuration = (days: number) => {
  if (days < 30) {
    return `${NumberToWord(days)} Days Plan`
  } else if (days >= 364) {
    const years = Math.floor(days / 364)
    return `${NumberToWord(years)} Years Plan`
  } else {
    const months = Math.round(days / 30)
    return `${NumberToWord(months)} Months Plan`
  }
}

// Map credit rating to risk level text
export const riskLevelMap: {[key: string]: string} = {
  A1: 'Very Low Risk',
  A2: 'Very Low Risk',
  A3: 'Very Low Risk',
  B1: 'Low Risk',
  B2: 'Low Risk',
  B3: 'Neutral',
  C1: 'Neutral',
  C2: 'acceptable',
  C3: 'Potential Risk',
  D1: 'High Risk',
  D2: 'High Risk',
  D3: 'High Risk',
  E: 'Impaired',
  F: 'Default Risk',
}

export const filefullpath = (filepath?: string) => {
  const filePath = filepath || ''
  // const publicBaseUrl = BASE_FILE_URL
  const publicBaseUrl = AWS_S3_FILE_URL
  const basePath = '/var/www/uploads'

  const fileName = filePath.replace(basePath, '').replace(/^\/+/, '') // Remove base path and leading slash
  const fileUrl = `${publicBaseUrl}/${fileName}` // Generate the public URL
  return fileUrl
}

