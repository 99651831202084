// @ts-nocheck
import { Column } from 'react-table';
import { SubscriptionsArchivedActionsCell } from './SubscriptionsArchivedActionsCell';
import { SubscriptionsArchivedSelectionCell } from './SubscriptionsArchivedSelectionCell';
import { SubscriptionsArchivedCustomHeader } from './SubscriptionsArchivedCustomHeader';
import { SubscriptionsArchivedSelectionHeader } from './SubscriptionsArchivedSelectionHeader';
import { SubscriptionsArchivedOrgNameCell } from './SubscriptionsArchivedOrgNameCell';
import { SubscriptionsArchivedPlanCell } from './SubscriptionsArchivedPlanCell';
import { SubscriptionsArchivedLinkCell } from './SubscriptionsArchivedLinkCell';
import { CreditWatch } from '../../core/_models';
import { SubscriptionsArchivedContactNameCell } from './SubscriptionsArchivedContactNameCell';
import { SubscriptionsArchivedRentalIDCell } from './SubscriptionsArchivedRentalIDCell';
import { SubscriptionsArchivedStatusCell } from './SubscriptionsArchivedStatusCell';
import { SubscriptionsArchivedCreditCheckCell } from './SubscriptionsArchivedCreditCheckCell';
import { SubscriptionsArchivedDateCell } from './SubscriptionsArchivedDateCell';
import { SubscriptionsArchivedWeeklyRateCell } from './SubscriptionsArchivedWeeklyRateCell';
import { SubscriptionsArchivedPackageCell } from './SubscriptionsArchivedPackageCell';

const SubscriptionsColumns: ReadonlyArray<Column<CreditWatch>> = [
  {
    Header: (props) => <SubscriptionsArchivedSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => (
      <SubscriptionsArchivedSelectionCell id={props.data[props.row.index].id} />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsArchivedCustomHeader tableProps={props} title='Rental Id' className='min-w-125px' />
      )
    },
    id: 'subscription_id',
    Cell: ({ ...props }) => {
      return <SubscriptionsArchivedRentalIDCell values={props.data[props.row.index]?.subscription_id} />
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsArchivedCustomHeader
          tableProps={props}
          title='Customer Name'
          className='min-w-125px'
        />
      );
    },
    id: 'firstname',
    Cell: ({ ...props }) => {
      return (
        <SubscriptionsArchivedContactNameCell
          values={props.data[props.row.index]}
        />
      );
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsArchivedCustomHeader
          tableProps={props}
          title='Organization Name'
          className='min-w-125px'
        />
      );
    },
    id: 'organization.organization_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsArchivedOrgNameCell values={props.data[props.row.index]} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsArchivedCustomHeader
        tableProps={props}
        title='Deal Owner'
        className='min-w-125px'
      />
    ),
    id: 'advance_information.sales_representative_name',
    Cell: ({ ...props }) => (
      <SubscriptionsArchivedPlanCell
        values={props.data[props.row.index]?.advance_information?.sales_representative_name === "undefined undefined" ? '-' : props.data[props.row.index]?.advance_information?.sales_representative_name}
      />
    ),
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsArchivedCustomHeader
          tableProps={props}
          title='Package'
          className='min-w-125px'
        />
      );
    },
    id: 'packages.package_name',
    Cell: ({ ...props }) => {
      return <SubscriptionsArchivedPackageCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => {
      return (
        <SubscriptionsArchivedCustomHeader
          tableProps={props}
          title='Weekly Rate'
          className='min-w-125px'
        />
      );
    },
    id: 'packages.weekly_rate',
    isNumber: true,
    Cell: ({ ...props }) => {
      return <SubscriptionsArchivedWeeklyRateCell values={props.data[props.row.index].packages[0] ?? '-'} />;
    },
  },
  {
    Header: (props) => (
      <SubscriptionsArchivedCustomHeader
        tableProps={props}
        title='Delivery Start Date'
        className='min-w-125px'
      />
    ),
    id: 'packages.deliveryDetails.deliveryDateTime',
    Cell: ({ ...props }) => (
      <SubscriptionsArchivedDateCell values={props.data[props.row.index].packages[0] ?? '-'} />
    ),
  },
  {
    Header: (props) => (
      <SubscriptionsArchivedCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-120px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <SubscriptionsArchivedActionsCell
        data={props.data[props.row.index]}
      />
    ),
  },
];

export { SubscriptionsColumns };
