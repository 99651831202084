import { FC } from 'react';

type Props = {
  values?: {
    package_name: string;
  };
};

const SubscriptionsArchivedPackageCell: FC<Props> = ({ values }) => {

  if (!values) return null;

  const { package_name } = values;

  return (
    <div className="text-gray-800">
      <span className='text-gray-800 text-hover-primary mb-1 cursor-pointer'>
        {package_name ? package_name : "-"}
      </span>
    </div>
  );
};

export { SubscriptionsArchivedPackageCell };
