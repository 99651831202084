/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

const SubscriptionsArchivedPagination = () => {

  const pagination = useQueryResponsePagination() as any

  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    updateState({ page })
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <ResponsivePagination
              current={pagination?.page}
              total={pagination?.totalPages}
              onPageChange={updatePage}
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export { SubscriptionsArchivedPagination }
