/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  values?: {
    organization: {
      organization_name: string;
      organization_id: string;
    };
  };
};

const SubscriptionsArchivedOrgNameCell: FC<Props> = ({ values }) => {
  const navigate = useNavigate();

  // Add a null check for values and organization
  if (!values || !values.organization) return null;

  const { organization: { organization_name, organization_id } } = values;

  const handleNavigation = () => {
    navigate('/customer-management/overview', { state: { organization_id } });
  };

  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'></div>
      <div className='d-flex flex-column' onClick={handleNavigation}>
        <span className='text-gray-800 text-hover-primary mb-1 cursor-pointer'>
          {organization_name}
        </span>
      </div>
    </div>
  );
};

export { SubscriptionsArchivedOrgNameCell };
