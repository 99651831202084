import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  values?: {
    firstname: string;
    lastname: string;
    email: string;
  };
};

const SubscriptionsArchivedContactNameCell: FC<Props> = ({ values }) => {

  const navigate = useNavigate();

  if (!values) return null;

  const { firstname, lastname, email } = values;

  const handleNavigation = () => {
    navigate('/customer-management/contact/overview', { state: { email } });
  };

  return (
    <div className="text-gray-800" onClick={handleNavigation}>
      <span className='text-gray-800 text-hover-primary mb-1 cursor-pointer'>
        {`${firstname ?? '-'} ${lastname ?? '-'}`}
      </span>
    </div>
  );
};

export { SubscriptionsArchivedContactNameCell };
