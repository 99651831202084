import { useEffect, useState } from 'react';
import { IPackages } from '../../../../../app/modules/subscriptions/create/ISubscription';
import { Packages } from '../../../../../app/modules/subscriptions/context/SubscriptionContext';
import { formatDuration } from '../../../../helpers/UtilityHelpers';

interface IMyProps {
  packages: Packages;
  packageList1: IPackages[];
  handleSelectPackage: (updatedPkg: object) => void;
  currentPackage: any;
}

const Step1: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props;
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handlePackageType = (packageItem: any, id: any) => {
    const typeObj = {
      ...newPackage,
      packageType: packageItem.name,
      packageId: id,
      contract_days: 0,
      min_contract_period:0
    };
    handleSelectPackage(typeObj);
    setSelectedPackage(id);
    setSelectedPlan(null);
  };

  const handlePackagePlan = (duration: any, id: string) => {
    handleSelectPackage({
      ...newPackage,
      contract_days: duration.contract_days,
      min_contract_period: duration.min_contract_period,
      total_KMs: duration.total_KMs,
      contracted_services: duration.contracted_services,
      tyres: duration.tyres,
    });
    setSelectedPlan(id);
  };

  const filteredPackages =
    props.currentPackage?.[0]?.package_name === 'Rent To Own'
      ? props.packageList1.filter((pkg) => pkg.name === 'Rent To Own')
      : props.packageList1;

  useEffect(() => {
    // Pre-select package
    if (newPackage.packageId) {
      setSelectedPackage(newPackage.packageId);

      const selectedPkg = props.packageList1.find((pkg) => pkg._id === newPackage.packageId) as any
      if (selectedPkg?.durations?.length > 0) {
        // Pre-select plan
        const selectedDuration = selectedPkg.durations.find(
          (d: any) => d.contract_days === newPackage.contract_days
        );
        if (selectedDuration) {
          setSelectedPlan(`option${selectedPkg.durations.indexOf(selectedDuration)}`);
        }
      }
    }
  }, [newPackage.packageId, newPackage.contract_days, props.packageList1]);

  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
          <span className="required">Package Types</span>
          <i
            className="fas fa-info-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Select your package category"
          ></i>
        </label>
        <div className="row g-4 mt-8">
          {filteredPackages &&
            filteredPackages.map((packageData, i) => (
              <div key={i} className="col-md-6">
                <div
                  className={`card p-4 ${selectedPackage === packageData._id
                    ? 'border-primary shadow-lg'
                    : 'border-light shadow-sm'
                    }`}
                  onClick={() => handlePackageType(packageData, packageData._id)}
                  style={{
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    transform:
                      selectedPackage === packageData._id ? 'scale(1.02)' : 'scale(1)',
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="symbol symbol-50px me-3">
                      {/* <span className="symbol-label bg-light-primary">
                        <KTIcon iconName="package" className="fs-1 text-primary" />
                      </span> */}
                      <span className="symbol-label bg-light-primary">
                        <i className="fas fa-box fs-1 text-primary"></i>
                      </span>
                    </span>
                    <div>
                      <h5 className="fw-bold mb-1">{packageData?.name}</h5>
                      <p className="text-muted small mb-0">Select a plan below</p>
                    </div>
                  </div>
                  {selectedPackage === packageData._id && packageData?.durations?.length > 0 && (
                    <div className="mt-3">
                      {packageData.durations
                        .filter((duration: any) => duration.contract_days !== 0)
                        .map((duration: any, ix: number) => (
                          <div
                            key={ix}
                            className={`p-3 mb-2 rounded ${selectedPlan === `option${ix}`
                              ? 'bg-primary text-white shadow'
                              : 'bg-light shadow-sm'
                              }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePackagePlan(duration, `option${ix}`);
                            }}
                            style={{ cursor: 'pointer', transition: 'all 0.2s ease' }}
                          >
                            {formatDuration(duration.contract_days)}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export { Step1 };
