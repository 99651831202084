import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {getMasterSearchSubscriptionsList} from '../../core/_requests'
import ExcelJS from 'exceljs'
import moment from 'moment'

const SubscriptionsMasterSearchToolbar = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const [rentals, setRentals] = useState([])

  useEffect(() => {
    getMasterSearchSubscriptionsList(`page=0&sort=subscription_id&order=asc`)
      .then((res: any) => {
        setRentals(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('rental-services')

    // Define columns
    worksheet.columns = [
      {header: 'Rental ID', key: 'subscription_id', width: 15},
      {header: 'First Name', key: 'firstname', width: 15},
      {header: 'Last Name', key: 'lastname', width: 15},
      {header: 'Email', key: 'email', width: 25},
      {header: 'Organization', key: 'organization', width: 20},
      {header: 'Package Name', key: 'package_name', width: 20},
      {header: 'Vehicle Name', key: 'vehicle_name', width: 25},
      {header: 'Signage Option', key: 'signageOption', width: 25},
      {header: 'Weekly Rate', key: 'weekly_rate', width: 15},
      {header: 'Contract Days', key: 'contract_days', width: 15},
      {header: 'Status', key: 'status', width: 10},
    ]

    // Add rows (Including subscriptions without packages)
    rentals.forEach((subscription: any) => {
      if (subscription.packages?.length > 0) {
        // If packages exist, add multiple rows for each package
        subscription.packages.forEach((pkg: any) => {
          worksheet.addRow({
            subscription_id: subscription.subscription_id,
            firstname: subscription.firstname,
            lastname: subscription.lastname,
            email: subscription.email,
            organization: subscription.organization?.organization_name || '',
            package_name: pkg.package_name || '',
            vehicle_name: pkg.vehicle_name || '',
            signageOption: pkg.signageOption || '',
            weekly_rate: pkg.weekly_rate || '',
            contract_days: pkg.contract_days || '',
            status:
              subscription.status === 0
                ? 'Pending'
                : subscription.status === 1
                ? 'Active'
                : subscription.status === 2
                ? 'Inactive'
                : 'Archived',
          })
        })
      } else {
        // If no packages exist, add a single row with empty package fields
        worksheet.addRow({
          subscription_id: subscription.subscription_id,
          firstname: subscription.firstname,
          lastname: subscription.lastname,
          email: subscription.email,
          organization: subscription.organization?.organization_name || '',
          package_name: '',
          vehicle_name: '',
          signageOption: '',
          weekly_rate: '',
          contract_days: '',
          status:
            subscription.status === 0
              ? 'Pending'
              : subscription.status === 1
              ? 'Active'
              : subscription.status === 2
              ? 'Inactive'
              : 'Archived',
        })
      }
    })

    // Apply styles
    worksheet.getRow(1).font = {bold: true}
    worksheet.columns.forEach((column) => {
      column.alignment = {horizontal: 'center'}
    })

    // Generate and trigger download
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)

    const todayDate = moment().format('DD-MM-YYYY')

    // Create a temporary link and trigger download
    const a = document.createElement('a')
    a.href = url
    a.download = `rental_service_${todayDate}.xlsx`
    document.body.appendChild(a)
    a.click()

    // Cleanup
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <SubscriptionsMasterSearchFilter /> */}

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3' onClick={handleExport}>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add User
      </button> */}
      {/* end::Add user */}
    </div>
  )
}

export {SubscriptionsMasterSearchToolbar}
