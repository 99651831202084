import { FC } from "react";
import moment from "moment";

type Props = {
  values?: {
    deliveryDetails?: {
      deliveryDateTime?: string;
    };
  };
};

const SubscriptionsArchivedDateCell: FC<Props> = ({ values }) => {
  const deliveryDateTime = values?.deliveryDetails?.deliveryDateTime;
  return (
    <div className="text-gray-800">
      {deliveryDateTime
        ? moment(deliveryDateTime).format("MMMM Do, YYYY")
        : "-"}
    </div>
  );
};

export { SubscriptionsArchivedDateCell };
