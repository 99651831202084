import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TransactionPage from '../modules/transactions/TransactionPage'
import VehiclePackagePage from '../modules/packages/vehiclePackage/VehiclePackagePage'
import ActiveAccountsPage from '../modules/activeAccounts/ActiveAccountsPage'
import SubscriptionInActivePage from '../modules/subscriptions/SubscriptionInActivePage'
import SubscriptionMasterSearchPage from '../modules/subscriptions/SubscriptionMasterSearchPage'
import SubscriptionArchivedPage from '../modules/subscriptions/SubscriptionArchivedPage'
import SubscriptionCWArchivedPage from '../modules/subscriptions/SubscriptionCWArchivedPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CategoryPage = lazy(() => import('../modules/packages/categories/CategoryPage'))
  const DiscountPage = lazy(() => import('../modules/rate-discount/DiscountPage'))
  const ViewPackageVehiclePage = lazy(() => import('../modules/packages/viewPackages/PackagesPage'))
  const CustomersPage = lazy(() => import('../modules/customer-management/CustomersPage'))
  const ViewCustomerPage = lazy(() => import('../modules/customer-management/ViewCustomerPage'))
  const CompanyPage = lazy(() => import('../modules/customer-management/CompanyPage'))
  const ViewCompanyPage = lazy(() => import('../modules/customer-management/ViewCompanyPage'))
  const VehiclesPage = lazy(() => import('../modules/vechicles/VehiclesPage'))
  const VehicleTypePage = lazy(() => import('../modules/vechicles/vehicletypes/VehicleTypePage'))
  const PartsAndAccessoriesPage = lazy(
    () => import('../modules/vechicles/partsAndAccessories/PartsAndAccessoriesPage')
  )
  const PackagesPage = lazy(() => import('../modules/subscriptions/packages/PackagesPage'))
  const SubscriptionPage = lazy(() => import('../modules/subscriptions/create/SubscriptionPage'))
  const InvoicePage = lazy(() => import('../modules/invoices/InvoicePage'))
  const InvoiceAgedReceivablesPage = lazy(() => import('../modules/invoices/InvoiceAgedReceivablesPage'))
  const ContactInvoicePage = lazy(() => import('../modules/contact-invoices/ContactInvoicePage'))
  const ViewInvoice = lazy(() => import('../modules/invoices/ViewInvoice'))
  const DownloadInvoice = lazy(() => import('../modules/invoices/DownloadInvoice'))
  const SubscriptionActivePage = lazy(
    () => import('../modules/subscriptions/SubscriptionActivePage')
  )
  const SubscriptionClosedPage = lazy(
    () => import('../modules/subscriptions/SubscriptionClosedPage')
  )
  // const// SubscriptionPendingApplications = lazy(
  //   () => import('../modules/subscriptions/SubscriptionPendingApplications')
  // )
  const SubscriptionCreditWatchPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCreditWatchPage')
  )
  const SubscriptionCWSalesPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWSalesPage')
  )
  const SubscriptionCWAccountsPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWAccounts')
  )
  const SubscriptionCWApprovedPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWApprovedPage')
  )
  const SubscriptionCWDeclinedPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWDeclinedPage')
  )
  const SubscriptionCWAdditionalDocReqPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWAdditionalDocReqPage')
  )
  const SubscriptionCWArchivedPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWArchivedPage')
  )
  const SubscriptionCWAllRentalsPage = lazy(
    () => import('../modules/subscriptions/SubscriptionCWAllRentalPage')
  )
  const SubscriptionViewPage = lazy(
    () => import('../modules/subscriptions/view/SubscriptionViewPage')
  )
  const SubscriptionEditPage = lazy(
    () => import('../modules/subscriptions/edit/SubscriptionEditPage')
  )
  const SubscriptionViewInvoicePage = lazy(
    () => import('../modules/subscriptions/createInvoice/SubscriptionCreateInvoicePage')
  )
  const SeeMoreTransactionPage = lazy(
    () => import('../modules/subscriptions/view/components/see-more-transactions/TransactionPage')
  )
  const SeeMoreInvoicePage = lazy(
    () => import('../modules/subscriptions/view/components/see-more-invoices/InvoicePage')
  )
  const CreditWatchDetails = lazy(
    () => import('../modules/subscriptions/view/ViewCreditWatchDetails')
  )
  const SubscriptionPendingApplications = lazy(
    () => import('../modules/subscriptions/SubscriptionPendingApplications')
  )

  const ExtraPackagesPage = lazy(
    () => import('../modules/packages/extraPackages/ExtraPackagesPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='customer-management/*'
          element={
            <SuspensedView>
              <CustomersPage />
              <ViewCustomerPage />
              <CompanyPage />
              <ViewCompanyPage />
            </SuspensedView>
          }
        />

        <Route
          path='vehicles/*'
          element={
            <SuspensedView>
              <VehiclesPage />
              <VehicleTypePage />
              <PartsAndAccessoriesPage />
              {/* <PackagesPage /> */}
            </SuspensedView>
          }
        />
        <Route
          path='subscriptions/*'
          element={
            <SuspensedView>
              <PackagesPage />
              <SubscriptionPage />
              <SubscriptionActivePage />
              <SubscriptionClosedPage />
              <SubscriptionInActivePage/>
              <SubscriptionMasterSearchPage/>
              <SubscriptionArchivedPage/>
              <SubscriptionPendingApplications />
              <SubscriptionViewPage />
              <SubscriptionEditPage />
              <SubscriptionViewInvoicePage />
              <SeeMoreTransactionPage />
              <SeeMoreInvoicePage/>
              <SubscriptionCreditWatchPage />
              <SubscriptionCWSalesPage />
              <SubscriptionCWAccountsPage />
              <SubscriptionCWApprovedPage />
              <SubscriptionCWDeclinedPage />
              <SubscriptionCWArchivedPage/>
              <SubscriptionCWAdditionalDocReqPage />
              <SubscriptionCWAllRentalsPage />
            </SuspensedView>
          }
        />

        <Route
          path='subscriptions/credit-watch-details'
          element={
            <SuspensedView>
              <CreditWatchDetails className='mb-5 mb-xl-8' />
            </SuspensedView>
          }
        />

        <Route
          path='invoices/*'
          element={
            <SuspensedView>
              <InvoicePage />
              <InvoiceAgedReceivablesPage />
            </SuspensedView>
          }
        />
        <Route
          path='invoices/view-invoice'
          element={
            <SuspensedView>
              <ViewInvoice className='mb-5 mb-xl-8' />
            </SuspensedView>
          }
        />
        <Route
          path='invoices/download-invoice'
          element={
            <SuspensedView>
              <DownloadInvoice className='mb-5 mb-xl-8' />
            </SuspensedView>
          }
        />
        {/* <Route
          path='invoices/aged/*'
          element={
            <SuspensedView>
              <InvoiceAgedReceivablesPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='contact-invoices/*'
          element={
            <SuspensedView>
              <ContactInvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionPage />
              {/* <CardPage /> */}
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='packages/*'
          element={
            <SuspensedView>
              <CategoryPage />
              <ExtraPackagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='extra/*'
          element={
            <SuspensedView>
              <ExtraPackagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='active/*'
          element={
            <SuspensedView>
              <ActiveAccountsPage />
            </SuspensedView>
          }
        />
        <Route
          path='vehicle/*'
          element={
            <SuspensedView>
              <VehiclePackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='vehicle/view/*'
          element={
            <SuspensedView>
              <ViewPackageVehiclePage />
            </SuspensedView>
          }
        />
        <Route
          path='/*'
          element={
            <SuspensedView>
              <DiscountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
